import { Severity } from '../NetcurioAlert/NetcurioAlert'

export enum PairFilesIcon {
	Warning = 'warning',
	Success = 'success',
	Stop = 'stop'
}

export enum ProcessFileUpload {
	SELECT = 'select',
	UPLOADING = 'uploading',
	FINISHED = 'finished'
}

export interface FileResult {
	files: Array<File>
	isLoading: boolean
	icon?: PairFilesIcon
	errorText?: string
	UUID?: string
}

export interface FilePairs {
	[nameFile: string]: FileResult
}

export interface ValidationFiles {
	severity: Severity
	message: string
}
