import {
	FilePairs,
	FileResult,
	FileUpload,
	NetcurioButton,
	NetcurioDialog,
	ProcessFileUpload,
	ValidationFiles
} from '@netcurio/frontend-components'
import styles from './CFDIUploadModal.module.scss'

import { PairFilesIcon } from '@netcurio/frontend-components/src'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import constants from '../../utilities/constants'

import { URLS } from '@netcurio/frontend-common'
import { FileEntry, uploadFiles } from '../../utilities/file-handling/upload-files'
import {
	validateFilesHavePairs,
	validateNewFiles,
	validateSizeOfFiles,
	validateTypeOfFile
} from '../../utilities/uploadFilesFuctions'

interface CFDIUploadModalProps {
	open: boolean
	onClose: () => void
	titleText: string
	waitUploadFilesText: string
	descriptionText: string | string[]
	redirectToDetail?: (detail: string) => void
	resultValidations: ({
		process,
		pairFilesUploaded
	}: {
		process: ProcessFileUpload
		pairFilesUploaded: number
	}) => Array<(filesResult: Array<FileResult>) => ValidationFiles | void>
	entityListUrl: string
	dragAndDropText?: string
	processButtonText?: string
	cancelButtonText?: string
	closeButtonText?: string
	stopButtonText?: string
	creationProcess: (
		xmlFile: FileEntry,
		pdfFile: FileEntry,
		filePairs: FilePairs,
		fileName: string
	) => Promise<any>
}

export const CFDIUploadModal = ({
	open,
	onClose,
	titleText,
	descriptionText,
	waitUploadFilesText,
	resultValidations,
	redirectToDetail,
	entityListUrl,
	dragAndDropText = t('dragAndDrop'),
	processButtonText = t('processText'),
	cancelButtonText = t('cancelButton'),
	closeButtonText = t('closeText'),
	stopButtonText = t('stopText'),
	creationProcess
}: CFDIUploadModalProps) => {
	const { SELECT, UPLOADING, FINISHED } = ProcessFileUpload
	const { PDF, XML } = constants.MIME_TYPES

	const acceptFileTypes = `${PDF},${XML}`

	const [canUpload, setCanUpload] = useState(false)
	const [stopUpload, setStopUpload] = useState(false)
	const [process, setProcess] = useState<ProcessFileUpload>(SELECT)
	const [filesToUpload, setFilesToUpload] = useState<Array<File>>([])
	const [filesResult, setFilesResult] = useState<Array<FileResult>>([])

	const filePairs: FilePairs = useMemo(() => {
		return filesToUpload.reduce((group: Record<string, any>, file) => {
			const nameTrimmed = file.name.substring(0, file.name.lastIndexOf('.'))
			group[nameTrimmed] = group[nameTrimmed] ?? { files: [], isLoading: false }
			group[nameTrimmed].files.push(file)
			return group
		}, {})
	}, [filesToUpload])

	const renameFilesExtension = (file: FileEntry) => {
		const originalName = file.name.split('.')
		const extensionFile = originalName.pop()
		const newName = originalName.concat() + '.' + extensionFile?.toLowerCase()
		file.name = newName
		return file
	}

	const pairFilesUploaded = filesResult.filter((file) => file.icon === PairFilesIcon.Success).length

	useEffect(() => {
		return () => {
			setProcess(SELECT)
			setFilesToUpload([])
			setStopUpload(false)
		}
	}, [open, SELECT])

	const filesErrorCounts = useMemo(() => {
		const filesPairs = Object.values(filePairs).map((fp) => fp.files) as Array<Array<File>>
		const filesNoPairs = filesPairs.filter((files) => files.length < 2).flat()
		return filesNoPairs.reduce((counts, file) => ({ ...counts, [file.type]: counts[file.type] + 1 }), {
			[PDF]: 0,
			[XML]: 0
		})
	}, [filePairs, PDF, XML])

	useEffect(() => {
		return () => {
			setProcess(SELECT)
			setFilesToUpload([])
			setStopUpload(false)
		}
	}, [open, SELECT])

	useEffect(() => setFilesResult(Object.values(filePairs)), [filePairs])
	const typeOfFileValidation = (files: Array<File>) => validateTypeOfFile(files, acceptFileTypes)
	const filesHavePairs = () => validateFilesHavePairs(filesErrorCounts)
	const validateFilesToUpload = [typeOfFileValidation, validateSizeOfFiles, filesHavePairs]

	const submitFiles = async (controller: AbortController, stopUpload: boolean) => {
		if (stopUpload) return

		const updatedFilePairs = { ...filePairs }
		setFilesResult(Object.values(updatedFilePairs))

		const uploadPromises = Object.entries(updatedFilePairs).map(async ([fileName, filePairsObj]) => {
			if (stopUpload) return
			updatedFilePairs[fileName] = { ...filePairsObj, isLoading: true }
			setFilesResult(Object.values(updatedFilePairs))

			const uploadResponse = await uploadFiles(filePairsObj.files, controller.signal)

			if (uploadResponse.successfulResponse) {
				const processedFiles = uploadResponse.successfulResponse.map(renameFilesExtension)

				const xmlFile = processedFiles.find((file) => file.name.includes('.xml'))
				const pdfFile = processedFiles.find((file) => file.name.includes('.pdf'))

				if (xmlFile && pdfFile) {
					const response = await creationProcess(xmlFile, pdfFile, updatedFilePairs, fileName)

					if (response.error) {
						updatedFilePairs[fileName] = {
							...updatedFilePairs[fileName],
							icon: PairFilesIcon.Warning,
							errorText: t(response.error)
						}
					} else {
						updatedFilePairs[fileName] = {
							...updatedFilePairs[fileName],
							icon: PairFilesIcon.Success,
							UUID: response.uuid
						}
					}
				}
			} else {
				updatedFilePairs[fileName] = {
					...updatedFilePairs[fileName],
					icon: uploadResponse.unknownError === '20' ? PairFilesIcon.Stop : PairFilesIcon.Warning,
					errorText: t(
						uploadResponse.unknownError === '20'
							? constants.UPLOAD_FILES.UPLOAD_FILES_ERRORS.UPLOADING_STOPPED
							: (uploadResponse.unknownError ?? '')
					)
				}
			}

			updatedFilePairs[fileName].isLoading = false
			setFilesResult(Object.values(updatedFilePairs))
		})

		await Promise.all(uploadPromises)
		setProcess(FINISHED)
	}

	useEffect(() => {
		let controller: AbortController
		if (process === UPLOADING) {
			controller = new AbortController()
			submitFiles(controller, stopUpload)
		}
		return () => {
			controller?.abort()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [process, stopUpload, UPLOADING, filePairs, creationProcess, FINISHED])

	const updateFilesToUpload = (fileList: FileList) =>
		setFilesToUpload((state) => validateNewFiles(fileList, state))

	const cancelUpload = () => setStopUpload(true)
	const processData = () => setProcess(UPLOADING)
	const deleteFile = (index: React.Key) => setFilesToUpload((state) => state.filter((_, i) => i !== index))

	const handleOnClose = () => {
		onClose()
		if (pairFilesUploaded === 1 && pairFilesUploaded === filesResult.length) {
			redirectToDetail?.(filesResult[0].UUID ?? entityListUrl)
		} else if (pairFilesUploaded > 0) {
			if (window.location.pathname === URLS.DASHBOARDS) location.href = entityListUrl
			else location.reload()
		}
	}

	const renderActionButton = () => {
		switch (process) {
			case SELECT:
				return (
					<NetcurioButton variant="contained" onClick={processData} disabled={!canUpload}>
						{processButtonText}
					</NetcurioButton>
				)

			case FINISHED:
				return <NetcurioButton disabled>{stopButtonText}</NetcurioButton>

			default:
				return (
					<NetcurioButton
						variant="contained"
						onClick={cancelUpload}
						disabled={process !== UPLOADING}
					>
						{stopButtonText}
					</NetcurioButton>
				)
		}
	}

	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<div className={styles.actionButtonsForDialog}>
					<NetcurioButton variant="text" onClick={handleOnClose} disabled={process === UPLOADING}>
						{process === SELECT ? cancelButtonText : closeButtonText}
					</NetcurioButton>
					{renderActionButton()}
				</div>
			}
			titleText={titleText}
			minWidth="960px"
			maxWidth="960px"
		>
			<FileUpload
				fileUploadDescription={
					typeof descriptionText === 'string' ? [descriptionText] : descriptionText
				}
				dragAndDropText={dragAndDropText}
				waitUploadFilesText={waitUploadFilesText}
				acceptFileTypes={acceptFileTypes}
				acceptMultipleFiles={true}
				process={process}
				filesToUpload={filesToUpload}
				filesResult={filesResult}
				validateFilesToUpload={validateFilesToUpload}
				validateFilesResult={resultValidations({ process, pairFilesUploaded })}
				setCanUpload={setCanUpload}
				updateFilesToUpload={updateFilesToUpload}
				deleteFile={deleteFile}
			/>
		</NetcurioDialog>
	)
}
