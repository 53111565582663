import { URLS } from '@netcurio/frontend-common'
import { FilePairs, FileResult, Severity, ValidationFiles } from '@netcurio/frontend-components'
import { PairFilesIcon } from '@netcurio/frontend-components/src'
import { ApolloQueryResult } from 'apollo-boost'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../hooks/useClient'
import { CreditMemo } from '../../types'
import { ExtraRequestParams } from '../../types/FileUpload'
import { FileEntry } from '../../utilities/file-handling/upload-files'
import { getCFDISATValidation, SATCFDIValidationResponse } from '../../utilities/getCFDISATValidation'
import { CFDIUploadModal } from '../CFDIUploadModal/CFDIUploadModal'
import { COMPANY_SETTINGS, CREATE_CREDIT_MEMO, CREDIT_MEMO_DETAIL, SET_SAT_ERROR } from './query'

interface NewCreditMemoModalProps {
	open: boolean
	onClose: () => void
	redirectToDetail?: (detail: string) => void
	extraRequestParams?: ExtraRequestParams
}

/**
 * NewCreditMemoModal
 * @param open <boolean>: If `true`, the component is shown.
 * @param onClose <void>: Callback fired when the component requests to be closed.
 * @param redirectToDetail <(detail: string) => void>: Function to execute a redirect
 * @param extraRequestParams <ExtraRequestParams>: Extra params for the request to the backend
 * @returns component
 */
export function NewCreditMemoModal({
	open,
	onClose,
	redirectToDetail,
	extraRequestParams
}: Readonly<NewCreditMemoModalProps>) {
	const { t } = useTranslation()
	const client = useClient()

	const getResultValidations = ({ pairFilesUploaded }: { pairFilesUploaded: number }) => {
		function filesUploaded(filesResult: Array<FileResult>): ValidationFiles {
			const total = filesResult.length
			const updated = pairFilesUploaded
			const CFDIName = t('creditMemoName')
			return {
				severity: total === updated ? Severity.Success : Severity.Info,
				message: t('updatedBillsText', { updated, total, CFDIName })
			}
		}

		function associationNeeded(): ValidationFiles | void {
			if (pairFilesUploaded > 0 && !extraRequestParams) {
				return { severity: Severity.Info, message: t('messageAssociationNeededCM') }
			}
		}

		return [filesUploaded, associationNeeded]
	}

	const createCreditMemo = async (
		xmlFile: FileEntry,
		pdfFile: FileEntry,
		filePairs: FilePairs,
		fileName: string
	) => {
		return await client
			.mutate({
				mutation: CREATE_CREDIT_MEMO,
				variables: {
					xml: xmlFile,
					pdf: pdfFile,
					...(extraRequestParams?.reference !== undefined
						? { reference: extraRequestParams?.reference }
						: {})
				}
			})
			.then(async (response) => {
				filePairs[fileName].UUID = response.data.createCreditMemo.uuid
				const { data }: ApolloQueryResult<{ CreditMemo: CreditMemo }> = await client.query({
					query: CREDIT_MEMO_DETAIL,
					variables: { uuid: filePairs[fileName].UUID }
				})

				const compSettings = await client.query({
					query: COMPANY_SETTINGS,
					variables: {
						rfc: data.CreditMemo.receiver.rfc
					}
				})

				if (compSettings && compSettings.data?.CompanySettings?.validate_sat) {
					const { isValid, error, messageError }: SATCFDIValidationResponse =
						await getCFDISATValidation({
							uuid: filePairs[fileName].UUID!,
							receiver: data.CreditMemo.receiver.rfc!,
							sender: data.CreditMemo.sender.rfc!,
							total: data.CreditMemo.total
						})
					if (error) {
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t('failedText')
					} else if (!isValid) {
						console.log('SET_SAT_ERROR')
						await client.mutate({
							mutation: SET_SAT_ERROR,
							variables: {
								uuid: filePairs[fileName].UUID,
								sat_validation_message: messageError ?? 'Error de vigencia del CFDI en el SAT'
							}
						})
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t('creditMemoNotValidSat')
					} else {
						filePairs[fileName].icon = PairFilesIcon.Success
					}
				} else {
					filePairs[fileName].icon = PairFilesIcon.Success
				}

				return response.data.createCreditMemo
			})
			.catch((error) => {
				return { error: error.message }
			})
	}

	return (
		<CFDIUploadModal
			open={open}
			onClose={onClose}
			redirectToDetail={redirectToDetail}
			creationProcess={createCreditMemo}
			titleText={t('uploadNewCreditMemo')}
			waitUploadFilesText={t('creditMemoBeingProcessed')}
			descriptionText={t('fileCreditMemoFormats')}
			entityListUrl={URLS.CREDIT_MEMO_LIST}
			resultValidations={getResultValidations}
		/>
	)
}
