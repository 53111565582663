import { dateFormatter, parseTimestampToTimeZone, Roles, URLS } from '@netcurio/frontend-common'
import { NetcurioGrid, TrackInformation, useNetcurioLoader } from '@netcurio/frontend-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AssociatedDocumentViewer } from '../../components/AssociatedDocumentViewer/AssociatedDocumentViewer'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { BackButton } from '../../components/HeaderButtons/BackButton'
import '../../style.scss'
import { ErrorWrapper, GoodReceipt, GoodReceiptItem, ITrackInformationDataGoodReceipt } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { redirectByRoles } from '../../utilities/validateUrlRoles'
import { ItemsList } from './ItemsList'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { GOODS_RECEIPT_DETAILS } from './queries'
import './style.scss'

export function GoodsReceiptDetail() {
	const history = useHistory()
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [informationTable, setInformationTable] = useState<Array<GoodReceiptItem>>([])
	const [informationGoodsReceipts, setInformationGoodsReceipts] = useState<GoodReceipt | undefined>()
	const [showMessageSuccessNewGR, setShowMessageSuccessNewGR] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()

	useEffect(() => {
		redirectByRoles(URLS.PO_LIST, Roles.SUPPLIER)
		getGoodReceiptData()
	}, [])

	function getGoodReceiptData() {
		showLoadingSpinner()
		const client = connection()
		const goodsReceipt = getUrlParameter('goodsReceipt')
		client
			.query({
				query: GOODS_RECEIPT_DETAILS,
				variables: {
					id: goodsReceipt
				}
			})
			.then((result) => {
				if (result.data.GoodsReceipt) {
					setInformationGoodsReceipts(result.data.GoodsReceipt)
					setInformationTable(result.data.GoodsReceipt.items)
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorMessage({
						code: errorCode,
						message: undefined
					})
				}
				hideLoadingSpinner()
			})
			.catch((error) => {
				console.log(error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setErrorMessage({
						code: errorCode,
						message: error.message
					})
				}
				hideLoadingSpinner()
			})
	}

	function actionButton() {
		history.push(URLS.GR_LIST)
	}

	const mapTrackInformationData = useCallback((): ITrackInformationDataGoodReceipt => {
		return {
			supplier: informationGoodsReceipts?.purchase_order?.supplier?.name ?? '',
			supplier_rfc: informationGoodsReceipts?.purchase_order?.supplier?.rfc ?? '',
			creation_date: dateFormatter.format(
				parseTimestampToTimeZone(informationGoodsReceipts?.created_at)
			),
			created_by_text:
				informationGoodsReceipts?.created_by?.name +
				' ' +
				informationGoodsReceipts?.created_by?.lastname,
			branch_office_PO: informationGoodsReceipts?.purchase_order?.branch_office ?? '',
			addressDelivery:
				informationGoodsReceipts?.purchase_order?.delivery_address_line_1 +
				' ' +
				(informationGoodsReceipts?.purchase_order?.delivery_address_line_2 !== null
					? informationGoodsReceipts?.purchase_order?.delivery_address_line_2
					: '') +
				' ' +
				informationGoodsReceipts?.purchase_order?.delivery_address_state +
				' ' +
				informationGoodsReceipts?.purchase_order?.delivery_address_postal_code +
				' ' +
				informationGoodsReceipts?.purchase_order?.delivery_address_country
		}
	}, [informationGoodsReceipts])

	useEffect(() => {
		if (!showMessageSuccessNewGR)
			if (sessionStorage.getItem('newGRmessage') === 'true') {
				sessionStorage.removeItem('newGRmessage')
				setShowMessageSuccessNewGR(true)
			}
	}, [sessionStorage])

	return (
		<NetcurioGrid
			container
			display="grid"
			gridTemplateRows="5.5rem 1fr"
			width="100%"
			height="100%"
			minHeight="100vh"
		>
			<AuthenticatedHeader>
				<div>
					<BackButton onClick={actionButton} translationKey={'comeBackEnrollmentText'} />
				</div>
				<div></div>
			</AuthenticatedHeader>
			<NetcurioGrid container>
				<NetcurioGrid
					item
					display="grid"
					gridTemplateRows="0.15fr 1fr"
					xs={8}
					sm={8}
					md={9}
					lg={9}
					xl={10}
				>
					<TrackInformation
						id={informationGoodsReceipts?.id}
						information={mapTrackInformationData()}
						title="titleDetailGR"
						gridTemplateAreas={`'supplier supplier_rfc creation_date'
								'created_by_text branch_office_PO addressDelivery'`}
						headerMessage={t('message-GR-new-success')}
						showHeaderMessage={showMessageSuccessNewGR}
						customerReference={informationGoodsReceipts?.customer_reference}
					/>
					<NetcurioGrid item marginTop="1rem">
						<ItemsList items={informationTable} />
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid item paddingLeft="1rem" xs={4} sm={4} md={3} lg={3} xl={2}>
					<AssociatedDocumentViewer
						referenceType={Constants.DOCUMENT_TYPE.PURCHASE_ORDER}
						documentReferenceId={informationGoodsReceipts?.purchase_order?.id ?? ''}
						customerReference={informationGoodsReceipts?.purchase_order?.customer_reference ?? ''}
						customerRFC={informationGoodsReceipts?.customer?.rfc ?? ''}
					/>
				</NetcurioGrid>
			</NetcurioGrid>
			<ErrorModal open={!!errorMessage?.code} errorCode={errorMessage?.code} />
		</NetcurioGrid>
	)
}
