import { Comment, URLS, dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	NetcurioGrid,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { ApolloQueryResult } from 'apollo-boost'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { BackButton } from '../../../components/HeaderButtons/BackButton'
import { Consumption, ConsumptionItem, ErrorWrapper, Invoice } from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import Formatter from '../../../utilities/formatter'
import { getUserRolesForCompany } from '../../../utilities/getUserRolesForCompany'
import { useDelayUnmount } from '../../../utilities/useDelayUnmount'
import { NewInvoiceModal } from '../../invoices/NewInvoiceModal/NewInvoiceModal'
import { RelatedInvoiceDetail } from '../../purcharse-orders/purchaseOrderDetail/RelatedInvoiceDetail/RelatedInvoiceDetail'
import {
	CONSUMPTION_COMMENTS,
	CONSUMPTION_DETAIL,
	CREATE_CONSUMPTION_COMMENT,
	INVOICES_DETAIL
} from '../graphQl'
import styles from './consumptionDetail.module.scss'
import { DetailField } from './DetailField/DetailField'
import { InvoicesTable } from './InvoicesTable/InvoicesTable'
import { ItemsList } from './ItemsList/ItemsList'
import { RejectPendingMessageConsumptionDetail } from './Modals/DiscardMessageConsumptionDetailModal/DiscardMessageConsumptionDetailModal'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'

export const ConsumptionDetail = () => {
	const { t } = useTranslation()
	const { search } = useLocation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const queryParams = new URLSearchParams(search)
	const client = connection()
	const history = useHistory()
	const userRoles = useMemo(() => getUserRolesForCompany(), [])
	const searchParams = new URLSearchParams(location.search)
	const [comments, setComments] = useState<Comment[]>([])
	const [consumption, setConsumption] = useState<Consumption | undefined>()
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()
	const [informationTable, setInformationTable] = useState<ConsumptionItem[]>([])
	const [showNewInvoiceModal, setShowNewInvoiceModal] = useState<boolean>(false)
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)
	const [informationInvoice, setInformationInvoice] = useState<Invoice>()
	const [showInfoInvoice, setShowInfoInvoice] = useState<boolean>(false)
	const [existMessagePending, setExistMessagePending] = useState<boolean>(false)
	const [dataInvoices, setDataInvoices] = useState<Array<Invoice>>([])
	const [showDiv, hideStyle] = useDelayUnmount(!isCommentsSectionExtended, 1000)
	const [openPendingMessageModal, setOpenPendingMessageModal] = useState<boolean>(false)

	useEffect(() => {
		getConsumptionInformation()
		getComments()
	}, [])

	const getConsumptionInformation = () => {
		showLoadingSpinner()
		const consumptionId = searchParams.get('consumption')
		const customerID = searchParams.get('customer')

		client
			.query({
				query: CONSUMPTION_DETAIL,
				variables: {
					id: consumptionId,
					customer: customerID
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ Consumption: Consumption }>) => {
				if (result.data.Consumption) {
					setConsumption(result.data.Consumption)
					setInformationTable(result.data.Consumption.items)
					setDataInvoices(result.data.Consumption.invoices ?? [])
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorMessage({
						code: errorCode
					})
				}
			})
			.catch(errorHandler)
			.finally(() => hideLoadingSpinner())
	}

	const getComments = () => {
		client
			.query({
				query: CONSUMPTION_COMMENTS,
				variables: {
					id: queryParams.get('consumption'),
					customer: queryParams.get('customer')
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ Consumption: Consumption }>) => {
				setComments(result.data.Consumption.comments ?? [])
			})
			.catch(errorHandler)
			.finally(() => {
				setExistMessagePending(false)
			})
	}

	const errorHandler = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorMessage({
				code: errorCode,
				message: error?.message
			})
		}
		hideLoadingSpinner()
	}

	const calcTotal = () => {
		const totalValue = informationTable?.reduce((acc, val) => (val.net_value ?? 0) + acc, 0)
		return Formatter.currency.format(totalValue)
	}

	const onAddComment = (newComment: Comment) => {
		const dataNewComment = {
			consumption: queryParams.get('consumption'),
			customer: queryParams.get('customer'),
			text: newComment.text
		}
		client
			.mutate({
				mutation: CREATE_CONSUMPTION_COMMENT,
				variables: dataNewComment
			})
			.then(() => {
				getComments()
			})
			.catch(errorHandler)
	}

	const onSizeChange = (commentSectionSize: CommentSectionSize): void => {
		setIsCommentsSectionExtended(commentSectionSize === CommentSectionSize.Extended)
	}

	const onChange = (pendingComment: string): void => {
		setExistMessagePending(!!pendingComment)
	}

	const getInformationInvoice = (idInvoice: string) => {
		showLoadingSpinner()
		client
			.query({
				query: INVOICES_DETAIL,
				variables: {
					uuid: idInvoice
				}
			})
			.then((result: ApolloQueryResult<{ Invoice: Invoice }>) => {
				setInformationInvoice(result.data.Invoice)
				hideLoadingSpinner()
				setShowInfoInvoice(true)
			})
			.catch(errorHandler)
	}

	const openInvoiceDetail = (uuid: string) => {
		getInformationInvoice(uuid)
	}

	const extraRequestParams = {
		reference: queryParams.get('consumption') ?? undefined,
		referenceType: Constants.DOCUMENT_TYPE.CONSUMPTION
	}

	const closePendingMessageModal = () => {
		setOpenPendingMessageModal(false)
	}

	return (
		<NetcurioGrid
			container
			display="grid"
			gridTemplateRows="5.5rem 1fr"
			width="100%"
			height="100%"
			minHeight="100vh"
		>
			<NetcurioGrid item alignItems="center" xs={12}>
				<AuthenticatedHeader>
					<div style={{ justifyContent: 'space-between' }}>
						<BackButton
							onClick={() => {
								if (existMessagePending) {
									setOpenPendingMessageModal(true)
								} else {
									history.push(URLS.CONSUMPTION_LIST)
								}
							}}
							translationKey={'comebackListText'}
						/>
					</div>
					<div></div>
				</AuthenticatedHeader>
			</NetcurioGrid>

			{showInfoInvoice ? (
				<RelatedInvoiceDetail
					invoiceInformation={informationInvoice}
					setShowNewInvoiceModal={setShowNewInvoiceModal}
					allInvoices={dataInvoices}
					openInvoiceDetail={openInvoiceDetail}
					setShowInfoInvoice={setShowInfoInvoice}
				/>
			) : (
				<NetcurioGrid container alignItems="flex-start">
					<NetcurioGrid item sm={8} md={9} xs={8} lg={9} xl={10} justifyContent="flex-start">
						<NetcurioGrid item className={styles.consumptionBackgroundInformationSection}>
							<NetcurioGrid item xs={12} className={styles.card}>
								<div>
									<span>{t('consumptionText')}</span>
									<span>{Formatter.id(consumption?.id)}</span>
								</div>
								{consumption?.customer_reference && (
									<div className={styles.referenceSection}>
										<span>
											{t('titleDetailCustomerReferenceGR', {
												customerReference: consumption?.customer_reference
											})}
										</span>
									</div>
								)}
							</NetcurioGrid>
							<NetcurioGrid item xs={12} className={styles.consumptionInformationSection}>
								<NetcurioGrid container className={styles.consumptionContainer}>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={t('createByText')}
											information={`${consumption?.created_by.name} ${consumption?.created_by.lastname}`}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={t('creationDate')}
											information={dateFormatter.format(
												parseTimestampToTimeZone(consumption?.created_at)
											)}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={t('branch_office_consumption')}
											information={consumption?.branch_office ?? ''}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={3}>
										<DetailField
											title={t('branchAddress')}
											information={`${consumption?.branch_address_line_1} ${consumption?.branch_address_state} ${consumption?.branch_address_postal_code} ${consumption?.branch_address_country}`}
										/>
									</NetcurioGrid>
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid item marginTop="1rem">
							<ItemsList dataTable={informationTable} />
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid
						item
						xs={4}
						sm={4}
						md={3}
						lg={3}
						xl={2}
						paddingLeft="1rem"
						height="100%"
						display="flex"
						flexDirection="column"
						gap="1.5rem"
					>
						{showDiv && (
							<NetcurioGrid
								item
								className={classNames(styles.totalContainerConsumption, {
									[styles.transitionHide]: hideStyle
								})}
							>
								<TotalContainerBar
									title={'netTotal'}
									fields={{}}
									total={{
										total: calcTotal()
									}}
									showTotalLine={false}
								/>
							</NetcurioGrid>
						)}
						<NetcurioGrid
							item
							className={classNames(styles.commentSection, {
								[styles.grownCommentsSection]: isCommentsSectionExtended
							})}
						>
							<CommentsSection
								onAddComment={onAddComment}
								comments={comments}
								variant={CommentSectionVariant.WithBody}
								onSizeChange={onSizeChange}
								onChange={onChange}
							/>
						</NetcurioGrid>

						{!isCommentsSectionExtended && (
							<InvoicesTable
								dataInvoices={consumption?.invoices}
								setShowNewInvoiceModal={setShowNewInvoiceModal}
								openInvoiceDetail={openInvoiceDetail}
								hasInvoice={!!consumption?.invoices?.length}
								userRoles={userRoles}
							/>
						)}
					</NetcurioGrid>
				</NetcurioGrid>
			)}
			<NewInvoiceModal
				open={showNewInvoiceModal}
				onClose={() => setShowNewInvoiceModal(false)}
				extraRequestParams={extraRequestParams}
				isCustomer={false}
				companyHasStandAloneEnable={false}
			/>
			<ErrorModal open={!!errorMessage?.code} errorCode={errorMessage?.code ?? ''} />
			<RejectPendingMessageConsumptionDetail
				open={openPendingMessageModal}
				close={() => closePendingMessageModal()}
				onAccept={() => history.push(URLS.CONSUMPTION_LIST)}
			/>
		</NetcurioGrid>
	)
}
