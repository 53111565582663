import { URLS } from '@netcurio/frontend-common'
import { FileResult, ProcessFileUpload, Severity } from '@netcurio/frontend-components'
import { ValidationFiles } from '@netcurio/frontend-components/src'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CFDIUploadModal } from '../../../components/CFDIUploadModal/CFDIUploadModal'
import { useClient } from '../../../hooks/useClient'
import { ExtraRequestParams } from '../../../types/FileUpload'
import { FileEntry } from '../../../utilities/file-handling/upload-files'
import { CREATE_INVOICE } from '../graphql/create-invoice'

interface NewInvoiceModalProps {
	open: boolean
	onClose: () => void
	redirectToDetail?: (detail: string) => void
	extraRequestParams?: ExtraRequestParams
	isCustomer: boolean
	companyHasStandAloneEnable: boolean
}

export function NewInvoiceModal({
	open,
	onClose,
	redirectToDetail,
	extraRequestParams,
	isCustomer,
	companyHasStandAloneEnable
}: NewInvoiceModalProps) {
	const { t } = useTranslation()
	const client = useClient()

	const getResultValidations = ({
		process,
		pairFilesUploaded
	}: {
		process: ProcessFileUpload
		pairFilesUploaded: number
	}) => {
		const nextSteps = () => {
			if (process === ProcessFileUpload.FINISHED && pairFilesUploaded) {
				return {
					severity: Severity.Info,
					message: t('followStepsInvoiceDetails')
				}
			}
			return
		}

		const filesUploaded = (filesResult: Array<FileResult>): ValidationFiles => {
			const total = filesResult.length
			const updated = pairFilesUploaded
			const CFDIName = t('invoiceName')
			return {
				severity: total === updated ? Severity.Success : Severity.Info,
				message: t('updatedBillsText', { updated, total, CFDIName })
			}
		}

		const associationNeeded = (): ValidationFiles | void => {
			if (!(isCustomer && companyHasStandAloneEnable) && pairFilesUploaded > 0 && !extraRequestParams) {
				return { severity: Severity.Info, message: t('messageAssociationNeeded') }
			}
		}

		return [nextSteps, filesUploaded, associationNeeded]
	}

	const createInvoice = async (xmlFile: FileEntry, pdfFile: FileEntry) => {
		return await client
			.mutate({
				mutation: CREATE_INVOICE,
				variables: {
					xml: xmlFile,
					pdf: pdfFile,
					...(extraRequestParams?.reference !== undefined
						? { reference: extraRequestParams?.reference }
						: {}),
					...(extraRequestParams?.referenceType !== undefined
						? { referenceType: extraRequestParams?.referenceType }
						: {})
				}
			})
			.then((response) => {
				return response.data.createInvoice
			})
			.catch((error) => {
				return { error: error.message }
			})
	}

	return (
		<CFDIUploadModal
			open={open}
			onClose={onClose}
			redirectToDetail={redirectToDetail}
			creationProcess={createInvoice}
			titleText={t('uploadNewInvoice')}
			waitUploadFilesText={t('invoicesBeingProcessed')}
			descriptionText={[t('fileInvoiceFormats'), t('associateInvoiceSteps')]}
			entityListUrl={URLS.INVOICE_LIST}
			resultValidations={getResultValidations}
		/>
	)
}
