import { URLS } from '@netcurio/frontend-common'
import { FileResult, PairFilesIcon, Severity, ValidationFiles } from '@netcurio/frontend-components'
import { FilePairs } from '@netcurio/frontend-components/src'
import { ApolloQueryResult } from 'apollo-boost'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../hooks/useClient'
import { PaymentComplement } from '../../types'
import { FileEntry } from '../../utilities/file-handling/upload-files'
import { getCFDISATValidation, SATCFDIValidationResponse } from '../../utilities/getCFDISATValidation'
import { CFDIUploadModal } from '../CFDIUploadModal/CFDIUploadModal'
import {
	COMPANY_SETTINGS,
	CREATE_PAYMENT_COMPLEMENT,
	PAYMENT_COMPLEMENT_DETAILS,
	VOID_PAYMENT_COMPLEMENT
} from './query'

interface NewPaymentComplementModalProps {
	open: boolean
	onClose: () => void
	redirectToDetail?: (detail: string) => void
}

export function NewPaymentComplementModal({
	open,
	onClose,
	redirectToDetail
}: Readonly<NewPaymentComplementModalProps>) {
	const { t } = useTranslation()
	const client = useClient()

	const getResultValidations = ({ pairFilesUploaded }: { pairFilesUploaded: number }) => {
		function filesUploaded(filesResult: Array<FileResult>): ValidationFiles {
			const total = filesResult.length
			const updated = pairFilesUploaded
			const CFDIName = t('paymentComplementName')
			return {
				severity: total === updated ? Severity.Success : Severity.Info,
				message: t('updatedBillsText', { updated, total, CFDIName })
			}
		}

		return [filesUploaded]
	}

	const createPaymentComplement = async (
		xmlFile: FileEntry,
		pdfFile: FileEntry,
		filePairs: FilePairs,
		fileName: string
	) => {
		return await client
			.mutate({
				mutation: CREATE_PAYMENT_COMPLEMENT,
				variables: {
					xml: xmlFile,
					pdf: pdfFile
				}
			})
			.then(async (response) => {
				filePairs[fileName].UUID = response.data.createPaymentComplement.uuid
				const { data }: ApolloQueryResult<{ PaymentComplement: PaymentComplement }> =
					await client.query({
						query: PAYMENT_COMPLEMENT_DETAILS,
						variables: {
							uuid: filePairs[fileName].UUID
						}
					})
				const compSettings = await client.query({
					query: COMPANY_SETTINGS,
					variables: {
						rfc: data.PaymentComplement.receiver.rfc
					}
				})

				if (compSettings && compSettings.data?.CompanySettings?.validate_sat) {
					const { isValid, error, messageError }: SATCFDIValidationResponse =
						await getCFDISATValidation({
							uuid: filePairs[fileName].UUID!,
							receiver: data.PaymentComplement.receiver.rfc,
							sender: data.PaymentComplement.sender.rfc,
							total: 0
						})

					if (error) {
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t('failedText')
					} else if (!isValid) {
						await client.mutate({
							mutation: VOID_PAYMENT_COMPLEMENT,
							variables: {
								uuid: filePairs[fileName].UUID,
								reason: messageError ?? 'Error de vigencia del CFDI en el SAT'
							}
						})
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t('paymentComplementNotValidSat')
					} else {
						filePairs[fileName].icon = PairFilesIcon.Success
					}
				} else {
					filePairs[fileName].icon = PairFilesIcon.Success
				}

				return response.data.createPaymentComplement
			})
			.catch((error) => {
				return { error: error.message }
			})
	}

	return (
		<CFDIUploadModal
			open={open}
			onClose={onClose}
			redirectToDetail={redirectToDetail}
			creationProcess={createPaymentComplement}
			titleText={t('uploadNewPaymentComplement')}
			waitUploadFilesText={t('paymentComplementBeingProcessed')}
			descriptionText={t('filePaymentComplementFormats')}
			entityListUrl={URLS.COMPLEMENT_LIST}
			resultValidations={getResultValidations}
		/>
	)
}
