import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import buttonStyles from '../../common/styles/button.module.scss'

interface BackButtonProps {
	onClick: () => void
	icon?: React.ReactNode
	translationKey?: string
}

export const BackButton = ({
	onClick,
	icon = <NetcurioIcons.ArrowBack />,
	translationKey = 'comeBackText'
}: BackButtonProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioButton
			variant="outlined"
			color="secondary"
			size="small"
			onClick={onClick}
			endIcon={icon}
			className={buttonStyles.button}
		>
			<span>{t(translationKey)}</span>
		</NetcurioButton>
	)
}
