/* global window, console, location, sessionStorage, URL */
import { Company, getCurrentUser, getDefaultAccessPage, URLS, User } from '@netcurio/frontend-common'
import * as queries from '../views/login/queries'
import { connection } from './connection'
import Constants from './constants'
import { getUrlParameter } from './getUrlParameter'
import UserInformation from './userInformation'

export function redirectToPage() {
	if (window.history.length > 2) {
		window.history.back()
	} else location.href = URLS.SITE
}

function getParameterSession(session: URL, param: string) {
	return session.searchParams.get(param)
}

const getDocTypeData = (docType: string, fromLogin: boolean, sessionPath: URL) => {
	let company: string | null = null
	let id: string | null = ''
	let requesterEmail: string | null = null
	let shouldAssignCompany: boolean = false
	let shouldAssignId: boolean = false
	let shouldAssignRequesterEmail: boolean = false

	let companyKey: string = ''
	let idKey: string = ''
	switch (docType) {
		case Constants.DOCUMENT_TYPE.PURCHASE_ORDER:
			shouldAssignCompany = true
			companyKey = 'customer'
			shouldAssignId = true
			idKey = 'order'
			break
		case Constants.DOCUMENT_TYPE.INVOICE:
			shouldAssignId = true
			idKey = 'invoice'
			break
		case Constants.DOCUMENT_TYPE.PAYMENT_COMPLEMENT:
			shouldAssignId = true
			idKey = 'paymentComplement'
			break
		case Constants.DOCUMENT_TYPE.REQUEST_FOR_QUOTATION:
			shouldAssignCompany = true
			companyKey = 'customer'
			shouldAssignId = true
			idKey = 'rfq'
			break
		case Constants.DOCUMENT_TYPE.CONSUMPTION:
			shouldAssignCompany = true
			companyKey = 'customer'
			shouldAssignId = true
			idKey = 'consumption'
			break
		case Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO:
			shouldAssignCompany = true
			companyKey = 'customer'
			shouldAssignId = true
			idKey = 'requestforcreditmemo'
			break
		case Constants.DOCUMENT_TYPE.CREDIT_MEMO:
			shouldAssignId = true
			idKey = 'creditmemo'
			break
		case Constants.DOCUMENT_TYPE.TICKET:
			shouldAssignCompany = true
			companyKey = 'sender'
			shouldAssignId = true
			idKey = 'ticket'
			break
		case Constants.DOCUMENT_TYPE.PURCHASE_REQUISITION:
			shouldAssignCompany = true
			companyKey = 'customer'
			shouldAssignId = true
			idKey = 'id'
			shouldAssignRequesterEmail = true
			break
		default:
			return { company, id, requesterEmail }
	}
	if (fromLogin) {
		if (shouldAssignId) {
			id = getParameterSession(sessionPath, idKey)
		}
		if (shouldAssignCompany) {
			company = getParameterSession(sessionPath, companyKey)
		}
		if (shouldAssignRequesterEmail) {
			requesterEmail = getParameterSession(sessionPath, 'requesterEmail')
		}
	} else {
		if (shouldAssignId) {
			id = getUrlParameter(idKey)
		}
		if (shouldAssignCompany) {
			company = getUrlParameter(companyKey)
		}
		if (shouldAssignRequesterEmail) {
			requesterEmail = getUrlParameter('requesterEmail')
		}
	}
	return { company, id, requesterEmail }
}

function getInformationDocType(fromLogin: boolean, sessionPath: URL): Promise<Company | undefined> {
	const docType = getDocType(fromLogin, sessionPath)
	const { id, company, requesterEmail } = getDocTypeData(docType, fromLogin, sessionPath)
	if (docType !== '') {
		const client = connection()
		return new Promise(function (resolve) {
			client
				.query({
					query: queries.DOCUMENT_FROM_LINK,
					variables: {
						id: id,
						company: company,
						requesterEmail: requesterEmail,
						docType: docType
					}
				})
				.then((result) => {
					const suppliersRfcs = result.data.getDocumentFromLink?.suppliers ?? []
					const customerRfc = result.data.getDocumentFromLink?.customer ?? ''
					const currentUser = getCurrentUser()
					const currentCompanyRfc = currentUser ? currentUser.company?.rfc : ''
					const companiesUnblocked = UserInformation.getCompaniesUnblocked()
					let companySelected
					if (currentCompanyRfc && [customerRfc, ...suppliersRfcs].includes(currentCompanyRfc)) {
						companySelected = UserInformation.getCompanySelected(
							companiesUnblocked,
							currentCompanyRfc
						)
					} else {
						companySelected = UserInformation.getCompanySelected(companiesUnblocked, customerRfc)
						if (companySelected === undefined) {
							companySelected = UserInformation.getCompanySelected(
								companiesUnblocked,
								suppliersRfcs[0]
							)
						}
					}
					sessionStorage.clear()
					resolve(companySelected)
				})
				.catch((error) => {
					console.error(error)
				})
		})
	} else {
		return Promise.resolve(undefined)
	}
}

export function validateMultiCompany(companiesUnblocked: Company[], goTo?: string) {
	const user = getCurrentUser()
	if (user) {
		UserInformation.setCurrentUser(user, companiesUnblocked[0])
	}
	if (companiesUnblocked.length > 1) {
		location.href = URLS.SELECT_COMPANY_AT_LOGIN
	} else if (user) {
		if (goTo !== undefined) {
			location.href = goTo
		} else {
			location.href = getDefaultAccessPage(user)
		}
		sessionStorage.clear()
	} else {
		location.href = URLS.SELECT_COMPANY_AT_LOGIN
	}
}

function getDocType(fromLogin: boolean, sessionPath: URL) {
	let docType = ''
	const evalDoc = fromLogin ? sessionPath.pathname : window.location.pathname

	switch (evalDoc) {
		case URLS.PO_DETAIL:
		case URLS.PO_EDIT:
			docType = Constants.DOCUMENT_TYPE.PURCHASE_ORDER
			break
		case URLS.INVOICE_DETAIL:
		case URLS.INVOICE_EDIT:
			docType = Constants.DOCUMENT_TYPE.INVOICE
			break
		case URLS.COMPLEMENT_DETAIL:
			docType = Constants.DOCUMENT_TYPE.PAYMENT_COMPLEMENT
			break
		case URLS.RFQ_DETAIL:
			docType = Constants.DOCUMENT_TYPE.REQUEST_FOR_QUOTATION
			break
		case URLS.CONSUMPTION_DETAIL:
			docType = Constants.DOCUMENT_TYPE.CONSUMPTION
			break
		case URLS.RFCM_DETAIL:
			docType = Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
			break
		case URLS.CREDIT_MEMO_DETAIL:
			docType = Constants.DOCUMENT_TYPE.CREDIT_MEMO
			break
		case URLS.TICKET_DETAIL:
			docType = Constants.DOCUMENT_TYPE.TICKET
			break
		case URLS.PURCHASE_REQUISITION_DETAIL:
			docType = Constants.DOCUMENT_TYPE.PURCHASE_REQUISITION
			break
	}
	return docType
}

function updateUser(newCompany: string): Promise<Company | undefined> {
	const client = connection()
	return new Promise(function (resolve) {
		client
			.mutate({
				mutation: queries.LOGIN_USER
			})
			.then((result) => {
				UserInformation.setCurrentUser(result.data.loginUser, undefined)
				const companiesUnblocked = UserInformation.getCompaniesUnblocked()
				const companySelected = UserInformation.getCompanySelected(companiesUnblocked, newCompany)
				resolve(companySelected)
			})
			.catch((error) => {
				console.error(error)
			})
	})
}

const removeLngParameterFromSearch = () => {
	const url = new URL(window.location.href)
	url.searchParams.delete('lng')
	return url.search
}

export function validationToRedirection(fromLogin: boolean) {
	return new Promise(function (resolve) {
		const user = getCurrentUser()
		if (user) {
			const companiesUnblocked = UserInformation.getCompaniesUnblocked()
			const defaultCompanyRFC = UserInformation.getDefaultCompany()

			if (companiesUnblocked.length > 0) {
				const { newCompany, sessionPath, searchUrl } = getNavigationData(fromLogin)
				if (sessionPath !== null) {
					if (shouldValidateMultiCompany(sessionPath, newCompany, searchUrl)) {
						validateNewCompany(user, resolve, newCompany, fromLogin, sessionPath)
					} else {
						/* Case URL of lists */
						validateUrlOfList(user, companiesUnblocked, resolve, sessionPath, defaultCompanyRFC)
					}
				} else {
					/* Cases of validation to status of Default Company */
					validationToStatusOfDefaultCompany(user, companiesUnblocked, resolve, defaultCompanyRFC)
				}
			} else {
				/*Case all Companies Blocked */
				resolve(Constants.AUTH.ALLCOMPANIESBLOCKED)
			}
		} else {
			resolve(undefined)
		}
	})
}

const shouldValidateMultiCompany = (sessionPath: URL, newCompany: string, searchUrl: string) => {
	return (
		(sessionPath.pathname === URLS.HOME && newCompany !== '') ||
		(newCompany === '' && searchUrl !== '' && sessionPath.pathname !== URLS.GR_DETAIL)
	)
}

const getNavigationData = (fromLogin: boolean) => {
	let newCompany = ''
	let sessionPath: Location | URL | null = null
	let searchUrl = ''
	if (fromLogin) {
		const internalUrl = sessionStorage.getItem('internalUrl')
		if (internalUrl !== null) {
			sessionPath = new URL(internalUrl)
			searchUrl = sessionPath.search
			newCompany = sessionPath.searchParams.get('companyRFC') ?? ''
		}
	} else {
		searchUrl = removeLngParameterFromSearch()
		newCompany = getUrlParameter('companyRFC')
		sessionPath = new URL(window.location.href)
	}
	return { newCompany, sessionPath, searchUrl }
}

const validateNewCompany = (
	user: User,
	resolve: (value: unknown) => void,
	newCompany: string,
	fromLogin: boolean,
	sessionPath: URL
) => {
	if (newCompany !== '') {
		updateUser(newCompany)
			.then((response) => {
				UserInformation.setCurrentUser(user, response)
				if (response !== undefined) {
					location.href = getDefaultAccessPage(user)
					resolve(undefined)
				} else {
					location.href = URLS.WITHOUT_ACCESS
					resolve(undefined)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	} else {
		/* Case URL of documents */
		getInformationDocType(fromLogin, sessionPath)
			.then((selectedCompany) => {
				if (selectedCompany !== undefined) {
					UserInformation.setCurrentUser(user, selectedCompany)
					if (fromLogin && sessionPath !== null) {
						location.href = sessionPath.href
					}
					resolve(selectedCompany)
				} else {
					location.href = URLS.WITHOUT_ACCESS
					resolve(selectedCompany)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}
}

const validateUrlOfList = (
	user: User,
	companiesUnblocked: Company[],
	resolve: (value: unknown) => void,
	sessionPath: URL,
	defaultCompanyRFC?: string
) => {
	let companySelected: Company | undefined
	if (!user.company) {
		if (defaultCompanyRFC) {
			companySelected = UserInformation.getCompanySelected(companiesUnblocked, defaultCompanyRFC)
		}
		if (companySelected !== undefined) {
			UserInformation.setCurrentUser(user, companySelected)
			if (sessionPath.href) {
				location.href = sessionPath.href
			} else {
				location.href = getDefaultAccessPage(user)
			}
			sessionStorage.clear()
		} else {
			validateMultiCompany(companiesUnblocked, sessionPath.href)
		}
	} else if (user.company && location.href !== sessionPath.href) {
		location.href = sessionPath.href
		sessionStorage.clear()
	}
	resolve(companySelected)
}

const validationToStatusOfDefaultCompany = (
	user: User,
	companiesUnblocked: Company[],
	resolve: (value: unknown) => void,
	defaultCompanyRFC?: string
) => {
	let companySelected
	if (defaultCompanyRFC) {
		companySelected = UserInformation.getCompanySelected(companiesUnblocked, defaultCompanyRFC)
		if (companySelected !== undefined) {
			UserInformation.setCurrentUser(user, companySelected)
			location.href = getDefaultAccessPage(user)
		} else {
			const statusCompanyDefault = UserInformation.getCompanySelected(user.companies, defaultCompanyRFC)
			if (statusCompanyDefault === undefined) {
				resolve(Constants.USER_STATUS.UNSUBSCRIBED)
			} else {
				resolve(Constants.USER_STATUS.BLOCKED)
			}
		}
	} else {
		validateMultiCompany(companiesUnblocked)
	}
	resolve(companySelected)
}
