import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PAYMENT_COMPLEMENT_DETAILS: DocumentNode = gql`
	query PaymentComplement($uuid: ID!) {
		PaymentComplement(uuid: $uuid) {
			uuid
			sender {
				name
				rfc
			}
			receiver {
				rfc
				name
			}
		}
	}
`

export const VOID_PAYMENT_COMPLEMENT = gql`
	mutation voidPaymentComplement($uuid: ID!, $reason: String!) {
		voidPaymentComplement(uuid: $uuid, reason: $reason) {
			code
			message
		}
	}
`

export const COMPANY_SETTINGS = gql`
	query CompanySettings($rfc: String!) {
		CompanySettings(rfc: $rfc) {
			tolerance
			validate_catalogue_on_po_creation
			validate_confirmed_on_gr_creation
			affect_consignment_stock
			validate_quantity_invoice_vs_consumption
			process_invoice_in_external_system
			validate_sat
		}
	}
`

export const CREATE_PAYMENT_COMPLEMENT = gql`
	mutation createPaymentComplement($xml: FileInput!, $pdf: FileInput!) {
		createPaymentComplement(xml: $xml, pdf: $pdf) {
			uuid
			message
		}
	}
`
